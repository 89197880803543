import { isEmpty, size } from 'lodash-es';
import { TFunction } from 'i18next';

import { TableFilter } from '../../../components/Table/components/filter/TableFilters';
import { Restriction, SearchType, SortDirection } from '../../../services/types/ApiTypes';
import { BackOfficeUserRole } from '../../../services/types/BoApiTypes';
import { TagSelectItem } from '../../../components/TagSelect/TagSelect';
import { SelectOptionValue } from '../../../components/Select/SelectOption';
import { getSupportedCountriesOfWorld } from '../../login/loginHelper';
import api from '../../../services/ApiServices';

import { BoUserListSearchParams } from './BOUserListViewActions';

export const DEFAULT_RESTRICTION = 'FirstName';
export const ALL_USERS_DEFAULT_RESTRICTION = 'GeneralSearchAllUsers';

const backOfficeUserRoleFilterValues = [
    BackOfficeUserRole.GOD_MODE,
    BackOfficeUserRole.SUPER_ADMIN,
    BackOfficeUserRole.TECHNICAL_SUPPORT,
    BackOfficeUserRole.PRODUCT_MANAGER,
    BackOfficeUserRole.RE_SELLER,
];

export const addAllRolesForAllUsersViews = (isAllUsers: boolean) => {
    const boUsersFilterValues = [...backOfficeUserRoleFilterValues];
    if (isAllUsers) {
        boUsersFilterValues.push(BackOfficeUserRole.NONE);
    }
    return boUsersFilterValues;
};

export const createRequest = (searchValue = '', page = 1, count = 15, columnName = DEFAULT_RESTRICTION, isAllUsersView = false): BoUserListSearchParams => {
    // add 'None' role for all users view
    const values = isAllUsersView ? addAllRolesForAllUsersViews(isAllUsersView) : backOfficeUserRoleFilterValues;

    const restrictions: Restriction[] = [
        {
            Field: 'Role',
            Value: null,
            Values: values,
            FieldSearchType: SearchType.NotSelected,
        },
    ];

    if (searchValue && searchValue.length > 0) {
        restrictions.unshift({
            Field: columnName,
            FieldSearchType: SearchType.NotSelected,
            Value: searchValue || null,
            Values: null,
        } as Restriction);
    }
    return {
        PagingOptions: {
            Count: count,
            Page: page,
        },
        Restrictions: [...restrictions],
        SortItems: [
            {
                SortColumn: DEFAULT_RESTRICTION, // default sorting by name
                SortDirection: SortDirection.Asc,
            },
        ],
        filters: {},
    };
};

export const BACK_OFFICE_USER_ROLES = {
    [BackOfficeUserRole.NONE]: 'view.backOffice.users.role.none',
    [BackOfficeUserRole.GOD_MODE]: 'view.backOffice.users.role.godMode',
    [BackOfficeUserRole.PRODUCT_MANAGER]: 'view.backOffice.users.role.productManager',
    [BackOfficeUserRole.RE_SELLER]: 'view.backOffice.users.role.reseller',
    [BackOfficeUserRole.SUPER_ADMIN]: 'view.backOffice.users.role.superAdmin',
    [BackOfficeUserRole.TECHNICAL_SUPPORT]: 'view.backOffice.users.role.technicalSupport',
};

export const getBoUserRoleLabel = (role: BackOfficeUserRole): string => {
    return BACK_OFFICE_USER_ROLES[role];
};

export const getBoUserRoleList = (t: TFunction): Array<SelectOptionValue<BackOfficeUserRole>> => {
    let roleKeys = Object.keys(BACK_OFFICE_USER_ROLES);
    roleKeys = roleKeys.filter((k: string) => k !== BackOfficeUserRole.NONE.toString()); // remove None role
    const roleList = roleKeys.map((key) => ({
        text: t(BACK_OFFICE_USER_ROLES[key]),
        value: key,
    }));
    // Push Revoke Access instead of None as the last role option
    roleList.push({ text: t('view.backOffice.users.role.revokeAccess'), value: BackOfficeUserRole.NONE.toString() });
    return roleList;
};

export const getBoUserRoleListForFilter = (t: TFunction, isAllUsersView: boolean): Array<TagSelectItem<any>> => {
    const allPossibleRoles = Object.keys(BACK_OFFICE_USER_ROLES);
    // Filter out 0 value (non-BO users with role 'NONE') for 'BackOffice Users' view
    const rolesForTheView = isAllUsersView ? allPossibleRoles : allPossibleRoles.filter((key) => key !== BackOfficeUserRole.NONE.toString());
    return rolesForTheView.map((key) => ({
        text: t(BACK_OFFICE_USER_ROLES[key]),
        value: key,
    }));
};

export function getBoUserListColumnName(columnName: string, translate?: TFunction): string {
    const columnNames = {
        FirstName: 'view.backOffice.users.column.name',
        LastName: 'view.backOffice.users.column.surname',
        UserName: 'view.backOffice.users.column.username',
        Email: 'view.backOffice.users.column.eMail',
        Country: 'view.backOffice.users.column.country',
        PersonalCode: 'view.backOffice.users.column.personalCode',
        UserNotes: 'view.backOffice.users.column.notes',
        Roles: 'view.backOffice.users.column.boRoles',
        Reseller: 'view.backOffice.users.column.reseller',
    };
    if (translate) {
        return translate(columnNames[columnName]);
    }
    return columnNames[columnName];
}

export const getBoUsersCountry = (country: string) => {
    return getSupportedCountriesOfWorld().find((c) => c.value === country);
};

export const parseRestrictions = (searchParams: BoUserListSearchParams, translate: TFunction) => {
    const appliedFilters: Array<TableFilter<any>> = [];
    searchParams.Restrictions.forEach((restriction) => {
        if (restriction.Field !== DEFAULT_RESTRICTION) {
            const newAppliedFilter: TableFilter<any> = {
                label: getBoUserListColumnName(restriction.Field, translate),
                columnName: restriction.Field,
                items: searchParams.filters[restriction.Field]?.items,
                loadItems: searchParams.filters[restriction.Field]?.loadItems,
                values: searchParams.filters[restriction.Field]?.values,
                tagSelectType: searchParams.filters[restriction.Field]?.tagSelectType,
                onSelectChangeCallback: searchParams.filters[restriction.Field]?.onSelectChangeCallback,
                placeholder: searchParams.filters[restriction.Field]?.placeholder,
                searchOnFocus: searchParams.filters[restriction.Field]?.searchOnFocus,
            };
            if (!!newAppliedFilter.values && !isEmpty(newAppliedFilter.values)) {
                appliedFilters.push(newAppliedFilter);
            }
        }
    });
    return appliedFilters;
};

export const parseFilters = (filter: TableFilter<any>): Restriction => {
    const appliedFilter: Restriction = {
        Field: filter.columnName,
        Value: null,
        Values: null,
        FieldSearchType: SearchType.NotSelected,
    };
    if (filter && filter.values.length > 1) {
        appliedFilter.Value = null;
        appliedFilter.Values = filter.values.map((val) => val.value);
        return appliedFilter;
    }
    if (filter && filter.values.length === 1) {
        appliedFilter.Values = null;
        appliedFilter.Value = filter.values[0].value;
        return appliedFilter;
    }
    // Filter length is 0 -> apply all values
    appliedFilter.Values = filter.items?.map((i) => i.value);
    return appliedFilter;
};

export const validateEmailBoUsers = async (value: string, resolve: any, userGuid?: string) => {
    try {
        const r = await api.boUsers.getUsers({
            Restrictions: [
                {
                    Field: 'Email',
                    Value: value,
                    Values: undefined,
                    FieldSearchType: SearchType.NotSelected,
                },
                {
                    Field: 'Role',
                    Value: null,
                    Values: addAllRolesForAllUsersViews(false),
                    FieldSearchType: SearchType.NotSelected,
                },
            ],
            SortItems: [
                {
                    SortColumn: 'Email',
                    SortDirection: SortDirection.Asc,
                },
            ],
            PagingOptions: {
                Page: 1,
                Count: 15,
            },
        });

        const otherEmail = r.data.Items.filter((i) => userGuid !== i.UserGuid && i.Email.toLowerCase() === value.toLowerCase());
        const duplicateEmail = size(otherEmail) === 0;
        resolve(duplicateEmail);
    } catch (error) {
        console.error(error);
        resolve(false);
    }
};

export const validateBoPersonalCodeField = async (value: string, country: string, resolve: any, userGuid?: string) => {
    try {
        const r = await api.boUsers.getUsers({
            Restrictions: [
                {
                    Field: 'PersonalCode',
                    Value: value,
                    Values: undefined,
                    FieldSearchType: SearchType.NotSelected,
                },
            ],
            SortItems: [
                {
                    SortColumn: 'PersonalCode',
                    SortDirection: SortDirection.Asc,
                },
            ],
            PagingOptions: {
                Page: 1,
                Count: 15,
            },
        });
        const otherPersonalCode = r.data.Items.filter((i) => userGuid !== i.UserGuid && i.PersonalCode === value && country === i.Country);
        const duplicatePersonalCode = size(otherPersonalCode) === 0;
        resolve(duplicatePersonalCode);
    } catch (error) {
        console.error(error);
        resolve(false);
    }
};

export const getRandomChar = (str: string) => {
    return str.charAt(Math.floor(Math.random() * str.length));
};

export const shuffle = (str: string) => {
    const array = str.split('');
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array.join('');
};

export const generatePassword = () => {
    const groups = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '1234567890', '!#$%&()*+,-./:;<=>?@[]^_`{|}~'];
    const length = 12;
    let pass = groups.map(getRandomChar).join('');

    const str = groups.join('');

    for (let i = pass.length; i <= length; i++) {
        pass += getRandomChar(str);
    }
    return shuffle(pass);
};
