import { SystemConfigurationDTO } from '../../../../../services/types/ApiTypes';

export const getSettingName = function(name: string) {
    if (name != null) {
        switch (name) {
            case 'IsConfirmAllAllowed':
                return 'views.company.index.ConfirmAllAllowed';
            case 'IsQuickConfirmAllowed':
                return 'views.company.index.QuickConfirmAllowed';
            case 'IsIWSImportEnabled':
                return 'views.company.index.IWSImportEnabled';
            case 'IsExportWithUnregisteredSupplierDenied':
                return 'views.company.index.ExportWithUnregisteredSupplierDenied';
            case 'ValidateExportXml':
                return 'views.company.index.ValidateExportXmlEstonianRegNo';
            case 'NewVendorNotificationLanguage':
                return 'views.company.index.NewSupplierLanguage';
            case 'ConfirmationFlowUserIdentifier':
                return 'views.company.index.confirmationFlowUserIdentifier';
            case 'IsKIEstonianSearchDisabled':
                return 'views.company.index.KIEstonianSearchDisabled';
            case 'CompanyRoundingOption':
                return 'views.company.rounding.selectOption';
            default:
                return 'views.company.index.' + name;
        }
    }
    return '';
};

export const languages = [
    {
        value: 'cs_CZ',
        text: 'Česky',
    },
    {
        value: 'de_DE',
        text: 'Deutsch',
    },
    {
        value: 'et_EE',
        text: 'Eesti',
    },
    {
        value: 'en_US',
        text: 'English',
    },
    {
        value: 'fr_FR',
        text: 'Français',
    },
    {
        value: 'fi_FI',
        text: 'Suomi',
    },
    {
        value: 'hr_HR',
        text: 'Hrvatski',
    },
    {
        value: 'lv_LV',
        text: 'Latviešu',
    },
    {
        value: 'lt_LT',
        text: 'Lietuvių',
    },
    {
        value: 'hu_HU',
        text: 'Magyar',
    },
    {
        value: 'nl_NL',
        text: 'Nederlands',
    },
    {
        value: 'sr_SL',
        text: 'Serbian',
    },
    {
        value: 'sk_SK',
        text: 'Slovenčina',
    },
    {
        value: 'bg_BG',
        text: 'Български',
    },
    {
        value: 'ru_RU',
        text: 'Русский',
    },
    {
        value: 'sr_SC',
        text: 'Српски',
    },
];

export const roundingOptions = [
    {
        value: '0',
        text: 'views.company.roundingOptions.off',
    },
    {
        value: '1',
        text: 'views.company.roundingOptions.newInvoiceRow',
    },
    {
        value: '2',
        text: 'views.company.roundingOptions.adjustBiggestAmount',
    },
];

export const pricePrecisionOptions = [
    {
        value: '2',
        text: 'views.company.pricePrecisionOptions.digits2',
    },
    {
        value: '4',
        text: 'views.company.pricePrecisionOptions.digits4',
    },
    {
        value: '7',
        text: 'views.company.pricePrecisionOptions.digits7',
    },
];

export const quantityPrecisionOptions = [
    {
        value: '2',
        text: 'views.company.pricePrecisionOptions.digits2',
    },
    {
        value: '4',
        text: 'views.company.pricePrecisionOptions.digits4',
    },
    {
        value: '7',
        text: 'views.company.pricePrecisionOptions.digits7',
    },
];

export const confirmationFlowUserIdentifierOptions = [
    {
        value: '1',
        text: 'views.company.confirmationFlowUserIdentifierOptions.personalCode',
    },
    {
        value: '2',
        text: 'views.company.confirmationFlowUserIdentifierOptions.personalCodeAndCountry',
    },
    {
        value: '3',
        text: 'views.company.confirmationFlowUserIdentifierOptions.email',
    },
];

export const invoiceExportOptions = [
    {
        value: '0',
        text: 'views.company.invoiceExportOptions.none',
    },
    {
        value: '1',
        text: 'views.company.invoiceExportOptions.originalPDFOnly',
    },
    {
        value: '2',
        text: 'views.company.invoiceExportOptions.allPDFFilesAndLinks',
    },
];

export const KIEstonianSearchOptions = [
    {
        value: '0',
        text: 'views.company.KIEstonianSearchOptions.off',
    },
    {
        value: '1',
        text: 'views.company.KIEstonianSearchOptions.on',
    },
];

export const InvoicesExportModes = [
    {
        text: 'views.company.InvoicesExportModes.disabled',
        value: '0',
    },
    {
        text: 'views.company.InvoicesExportModes.onRequest',
        value: '1',
    },
    {
        text: 'views.company.InvoicesExportModes.push',
        value: '2',
    },
];

export const ExportDelimiterSymbols = [
    {
        text: 'views.company.index.ExportCSVDelimiterSymbol.Tab',
        value: '\t',
    },
    {
        text: 'views.company.index.ExportCSVDelimiterSymbol.Semicolon',
        value: ';',
    },
];

export const MonetaryNetVatTotalPrecision = [
    {
        text: 'views.company.monetaryNetVatTotalPrecisionOptions.2',
        value: '2',
    },
    {
        text: 'views.company.monetaryNetVatTotalPrecisionOptions.4',
        value: '4',
    },
];

export const isDropDownSetting = (settingName: string): boolean => {
    const settingList = [
        'InvoicesExportMode',
        'DefaultCurrency',
        'IsKIEstonianSearchDisabled',
        'IncludePdfIntoExport',
        'NewVendorNotificationLanguage',
        'ConfirmationFlowUserIdentifier',
        'CompanyRoundingOption',
        'MonetaryAmountsItemPricePrecision',
        'MonetaryAmountsQuantityPrecision',
        'ExportCSVDelimiterSymbol',
        'MonetaryNetVatTotalPrecision',
    ];
    return settingList.includes(settingName);
};

export const getDropDownList = (settingName: string) => {
    if (settingName != null) {
        switch (settingName) {
            case 'InvoicesExportMode':
                return InvoicesExportModes;
            case 'DefaultCurrency':
                return InvoicesExportModes;
            case 'IsKIEstonianSearchDisabled':
                return KIEstonianSearchOptions;
            case 'IncludePdfIntoExport':
                return invoiceExportOptions;
            case 'NewVendorNotificationLanguage':
                return languages;
            case 'ConfirmationFlowUserIdentifier':
                return confirmationFlowUserIdentifierOptions;
            case 'CompanyRoundingOption':
                return roundingOptions;
            case 'MonetaryAmountsItemPricePrecision':
                return pricePrecisionOptions;
            case 'MonetaryAmountsQuantityPrecision':
                return quantityPrecisionOptions;
            case 'ExportCSVDelimiterSymbol':
                return ExportDelimiterSymbols;
            case 'MonetaryNetVatTotalPrecision':
                return MonetaryNetVatTotalPrecision;
            default:
                return null;
        }
    }
    return null;
};

export enum CompanySettingStatus {
    Disabled = '0',
    Enabled = '1',
}

export enum CompanySettingType {
    Boolean = 'Boolean',
    String = 'String',
    Object = 'Object',
}

export enum CompanySettingCombinedRowDescriptionType {
    FirstRow = '_firstRow',
    CustomRow = 'customRow',
    IncludeVat = '_includeVatAndGroupingCriteria',
    WithoutSuffix = '_withoutSuffix',
}

export const parseCombinedRowDescriptionValue = (value: string) => {
    return value.indexOf(':::') === -1
        ? null
        : {
              firstValue: !value.split(':::')[0]?.includes('_') ? CompanySettingCombinedRowDescriptionType.CustomRow : value.split(':::')[0],
              secondValue: value.split(':::')[1],
          };
};

export const getCombinedRowDescriptionSettingName = function(name: string) {
    if (name != null) {
        switch (name) {
            case CompanySettingCombinedRowDescriptionType.FirstRow:
                return 'views.CompanySettings.Setting.CombinedRow.FirstRow';
            case CompanySettingCombinedRowDescriptionType.IncludeVat:
                return 'views.CompanySettings.Setting.CombinedRow.IncludeVat';
            case CompanySettingCombinedRowDescriptionType.WithoutSuffix:
                return 'views.CompanySettings.Setting.CombinedRow.WithoutSuffix';
            default:
                return 'views.CompanySettings.Setting.CombinedRow.CustomRow';
        }
    }
    return '';
};

export const getCombinedRowDescriptionSettingLists = function(setting: SystemConfigurationDTO) {
    const lists = [];
    lists.push(
        [
            {
                text: getCombinedRowDescriptionSettingName(CompanySettingCombinedRowDescriptionType.FirstRow),
                value: CompanySettingCombinedRowDescriptionType.FirstRow,
                radioButton: true,
            },
            {
                text: 'views.CompanySettings.Setting.CombinedRow.CustomRow',
                value: CompanySettingCombinedRowDescriptionType.CustomRow,
                radioButton: true,
                withInput: true,
                mandatoryField: true,
                inputValue: !setting.Value.split(':::')[0].includes('_') ? setting.Value.split(':::')[0] : '',
            },
        ],
        [
            {
                text: getCombinedRowDescriptionSettingName(CompanySettingCombinedRowDescriptionType.IncludeVat),
                value: CompanySettingCombinedRowDescriptionType.IncludeVat,
                radioButton: true,
            },
            {
                text: getCombinedRowDescriptionSettingName(CompanySettingCombinedRowDescriptionType.WithoutSuffix),
                value: CompanySettingCombinedRowDescriptionType.WithoutSuffix,
                radioButton: true,
            },
        ],
    );

    return lists;
};

export enum CompanySettingIncludePdfIntoExportType {
    None = '0',
    OriginalInvoicePdf = '1',
    OriginalInvoiceXml = '2',
    OtherPdfs = '3',
    NonPdfs = '4',
    Links = '5',
    DocumentLink = '6',
}

export const getIncludePdfIntoExportSettingName = function(name: string) {
    switch (name) {
        case CompanySettingIncludePdfIntoExportType.None:
            return 'views.CompanySettings.Setting.IncludePdfIntoExport.None';
        case CompanySettingIncludePdfIntoExportType.OriginalInvoicePdf:
            return 'views.CompanySettings.Setting.IncludePdfIntoExport.OriginalInvoicePdf';
        case CompanySettingIncludePdfIntoExportType.OriginalInvoiceXml:
            return 'views.CompanySettings.Setting.IncludePdfIntoExport.OriginalInvoiceXml';
        case CompanySettingIncludePdfIntoExportType.OtherPdfs:
            return 'views.CompanySettings.Setting.IncludePdfIntoExport.OtherPdfs';
        case CompanySettingIncludePdfIntoExportType.NonPdfs:
            return 'views.CompanySettings.Setting.IncludePdfIntoExport.NonPdfs';
        case CompanySettingIncludePdfIntoExportType.Links:
            return 'views.CompanySettings.Setting.IncludePdfIntoExport.Links';
        case CompanySettingIncludePdfIntoExportType.DocumentLink:
            return 'views.CompanySettings.Setting.IncludePdfIntoExport.DocumentLink';
        default:
            return '';
    }
};

export const getIncludePdfIntoExportSettingLists = function() {
    const lists = [];
    lists.push(
        [
            {
                text: getIncludePdfIntoExportSettingName(CompanySettingIncludePdfIntoExportType.OriginalInvoicePdf),
                value: CompanySettingIncludePdfIntoExportType.OriginalInvoicePdf,
                checkBox: true,
            },
            {
                text: getIncludePdfIntoExportSettingName(CompanySettingIncludePdfIntoExportType.OriginalInvoiceXml),
                value: CompanySettingIncludePdfIntoExportType.OriginalInvoiceXml,
                checkBox: true,
            },
            {
                text: getIncludePdfIntoExportSettingName(CompanySettingIncludePdfIntoExportType.OtherPdfs),
                value: CompanySettingIncludePdfIntoExportType.OtherPdfs,
                checkBox: true,
            },
            {
                text: getIncludePdfIntoExportSettingName(CompanySettingIncludePdfIntoExportType.NonPdfs),
                value: CompanySettingIncludePdfIntoExportType.NonPdfs,
                checkBox: true,
            },
            {
                text: getIncludePdfIntoExportSettingName(CompanySettingIncludePdfIntoExportType.Links),
                value: CompanySettingIncludePdfIntoExportType.Links,
                checkBox: true,
            },
            {
                text: getIncludePdfIntoExportSettingName(CompanySettingIncludePdfIntoExportType.DocumentLink),
                value: CompanySettingIncludePdfIntoExportType.DocumentLink,
                checkBox: true,
            },
        ],
        [],
    );

    return lists;
};

export const parseIncludePdfIntoExportValue = (value: string | null): string[] => {
    if (!value) {
        return [CompanySettingIncludePdfIntoExportType.None];
    }
    const SEPARATOR = ':::';
    return value.indexOf(SEPARATOR) === -1 ? [value || CompanySettingIncludePdfIntoExportType.None] : value.split(SEPARATOR);
};
